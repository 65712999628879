/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

const identityProviderConfig = {
    PROVIDERS_KEY: 'auth:identity-provider',
    DEFAULT: {
        key: 'auth:ropc-default',
        kind: 'oauth',
    },
    KERBEROS: {
        key: 'auth-kerberos:ad',
        kind: 'kerberos'
    },
    OKTA: {
        key: 'auth:oauth-okta',
        kind: 'oauth',
    }
};

export { identityProviderConfig };
