/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import { getProviderLinks } from './get-provider-links';

const headers = new Headers();
headers.append('Content-Type', 'application/x-www-form-urlencoded');
headers.append('Accept', 'application/json');
headers.append('Authorization', 'Basic Y29tLmF2aWQubWVkaWFjZW50cmFsY2xvdWQtYTk1YjNmOTM4NDk3NWE1MzVjZTAwNTMzMTUzMWY2ZmI6Yzg2M2' +
    'JhNmM0ZTAzZDM1NWEzZDI5NjY0MWQ2NTY2ZWYzZTU0Y2ZiZTU1MzQ5NjliNWY3MmY1YmQ5NTZhNzE5MQ==');

function fetchIdentityProviders() {
    return fetch('/auth/identity-providers', {
        method: 'get',
        credentials: 'same-origin',
        headers
    });
}

export function fetchIdentityProviderLinks() {
    return fetchIdentityProviders()
        .then((response) => {
            const { status } = response;
            if (status === 200) {
                return response.json()
                    .then(getProviderLinks);
            }
            const errorMessage = `[Identity provider] Failed to get identity providers, response ${response.status}`;
            console.warn(errorMessage);
            throw new Error(errorMessage);
        });
}
