/*
 * Copyright 2018, 2020-2021 by Avid Technology, Inc.
 */
import localizationStorage from './localization/storage';

const getLoginErrors = () => ({
    WRONG_CREDENTIALS: {
        status: 400,
        error: 'invalid_grant',
        message: localizationStorage.getLocalization('wrong-credentials-error')
    },
    LICENSE_QUOTA_EXCEEDED: {
        status: 402,
        code: 'avid.iam/QUOTA_EXCEEDED',
        message: localizationStorage.getLocalization('licence-quota-exceeded-error')
    },
    USER_NOT_IN_GROUP: {
        status: 404,
        code: 'avid.iam/NOT_FOUND',
        message: localizationStorage.getLocalization('user-not-in-group-error')
    },
    LICENSE_SEATS_ENTITLEMENT_EXPIRED: {
        status: 410,
        code: 'avid.iam/SEATS_ENTITLEMENT_EXPIRED',
        message: localizationStorage.getLocalization('licence-seat-entitlement-expired-error')
    },
    TIMEOUT_USER_AUTHENTICATION: {
        status: 503,
        code: 'avid.iam/SERVICE_UNAVAILABLE',
        params: {
            reason: 'idp_timeout'
        },
        message: localizationStorage.getLocalization('timeout-user-authentication-error')
    },
    ACCOUNT_LOCKED_OR_DISABLED: {
        status: 403,
        code: 'avid.iam/FORBIDDEN',
        params: {
            reason: 'account_locked_or_disabled_in_idp',
        },
        message: localizationStorage.getLocalization('account-locked-or-disabled-error')
    },
    KERBEROS_UNAUTHORIZED: {
        status: 401,
        code: 'avid.upstream/UNAUTHENTICATED',
        message: localizationStorage.getLocalization('generic-login-error')
    },
    KERBEROS_NOT_ALLOWED: {
        status: 405,
        code: 'avid.upstream/METHOD_NOT_ALLOWED',
        message: localizationStorage.getLocalization('generic-login-error')
    },
    KERBEROS_NOT_FOUND: {
        status: 404,
        code: 'avid.upstream/METHOD_NOT_FOUND',
        message: localizationStorage.getLocalization('generic-login-error')
    }
});

const getValidateMessages = () => ({
    WRONG_CREDENTIALS: localizationStorage.getLocalization('wrong-credentials-error'),
});

export {
    getLoginErrors,
    getValidateMessages,
};
