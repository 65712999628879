/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

// region Methods: Private

function status(response) {
    if ((response.status >= 200 && response.status < 300) || response.ok) {
        return Promise.resolve(response.json());
    }
    return Promise.reject(response);
}

function addParamsToUrl(url, params) {
    const paramString = params && Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
    if (paramString) {
        return `${url}?${paramString}`;
    }
    return url;
}

function error(err) {
    return Promise.reject({
        status: err && err.status,
        response: err && err.response
    });
}

const ACCEPT = 'application/json;charset=UTF-8';

// endregion

// region Methods: Public

function get(url, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    return fetch(urlWithParams, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            Accept: ACCEPT,
        }
    })
        .then(status)
        .catch(error);
}

function put(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    return fetch(urlWithParams, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            Accept: ACCEPT,
            'Content-Type': ACCEPT,
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            if ((response.status >= 200 && response.status < 300) || response.ok) {
                return Promise.resolve();
            }
            return Promise.reject(response);
        })
        .catch(error);
}

// endregion

export { get, put };
