/*
 * Copyright 2018, 2020-2021 by Avid Technology, Inc.
 */

import fetchUserSettings from '../settings/fetchSettings';
import updateUserSettings from './updateUserSettings';
import fetchGeneralSystemSettings from './fetchGeneralSystemSettings';
import initGeneralSystemSettings from './initGeneralSystemSettings';
import initUserSettings from '../settings/initUserSettings';
import localizationStorage from '../login/localization/storage';
import { USER_LOCALE_KEY } from '../constants';
import isAdminLoginCheck from '../login/admin/isAdminLoginCheck';

export default () => {
    return Promise.all([fetchGeneralSystemSettings(), fetchUserSettings()])
        .then(([generalSystemSettings, userSettings]) => {
            initGeneralSystemSettings(generalSystemSettings);
            if (isAdminLoginCheck()) {
                return userSettings;
            }
            const loginLocale = localizationStorage.getLocale();
            if (userSettings.locale !== loginLocale) {
                localStorage.setItem(USER_LOCALE_KEY, loginLocale);
                const nextSettings = {
                    ...userSettings,
                    locale: loginLocale,
                };
                return updateUserSettings(nextSettings)
                    .catch((error) => {
                        localStorage.setItem(USER_LOCALE_KEY, userSettings.locale);
                        console.warn('User Settings update failed. Use previous settings. Error:', error);
                        return userSettings;
                    });
            }
            localStorage.setItem(USER_LOCALE_KEY, userSettings.locale || loginLocale);
            return userSettings;
        })
        .then((userSettings) => {
            initUserSettings(userSettings, isAdminLoginCheck());
        })
        .catch((error) => {
            console.warn(`Initializing settings has failed with error: ${error}.`);
            AV.internal.generalSystemSettings = {};
            AV.internal.userSettings = {};
        });
};
