/*
 * Copyright 2018-2021 by Avid Technology, Inc.
 */

import initMode from '../../mode/init';
import getEmbeddedInMode from '../../util/getEmbeddedInMode';
import { validateEntitlements } from '../../util/validateEntitlements';
import getFailureMessage from './getFailureMessage';
import '../../module-loader/main/system-properties/SystemProperties';

const getJson = (response) => {
    return Promise.resolve().then(() => {
        return response.json();
    }).catch((err) => {
        console.warn('Error with parsing login result:', err);
        throw new Error('Unexpected response');
    });
};

export default ({ response, isAdministrator }) => {
    const { status } = response;
    const result = {
        isValid: false,
        message: ''
    };
    if (status === 200) {
        return getJson(response)
            .then(initMode)
            .then(AV.SystemProperties.init)
            .then(() => {
                if (!isAdministrator) {
                    const entitlements = AV.SystemProperties.get('core.entitlements', []);
                    const embeddedInMode = getEmbeddedInMode();
                    return validateEntitlements(entitlements, embeddedInMode).then((validateInfo) => {
                        if (!validateInfo.isValid) {
                            AV.LoginProvider.endSession();
                            result.message = validateInfo.message || '';
                            return result;
                        }
                        result.isValid = true;
                        return result;
                    });
                }
                result.isValid = true;
                return result;
            });
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        result.message = msg || '';
        return result;
    });
};

export const onOktaLoginResponse = ({ response }) => {
    const { status } = response;
    if (status === 200) {
        return getJson(response)
            .then((redirectData) => {
                window.location.assign(redirectData.location);
                return Promise.resolve({ oktaRedirect: true });
            });
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        return {
            isValid: false,
            message: msg || '',
        };
    });
};
