/*
 * Copyright 2018-2021 by Avid Technology, Inc.
 */
import { AV } from './av';
import '../../login/main/login-provider';
import loggedCheck from './logged-check';
import { handleFatalError, handleAccessDeniedError } from '../../util/fatal-error';
import getUserConfig from './userConfig';
import getIsAdministrator from './getIsAdministrator';
import validateLocation from './validateLocation';
import initLoginPromise from '../../login/main/init-login-promise';
import initEulaPromise from '../../eula/main/initEulaPromise';
import saveToken from './save-token';
import saveIamTokenData from './saveIamTokenData';
import initSettingsPromise from './initSettingsPromise';
import User from './AV.User';
import initStore from '../../systemsStore/initStore';
import getApplicationProfile from '../../util/application-profile';
import {
    showChromeVersionError,
    MINIMAL_CHROME_VERSION,
} from './chromeVersionError';
import browserInfo from './browserInfo';
import setPageTitle from './setPageTitle';

AV.internal = AV.internal || {};
/* eslint-disable consistent-return */
export default () => {
    const { isChrome, chromeVersion } = browserInfo();

    if (isChrome && (chromeVersion < MINIMAL_CHROME_VERSION)) {
        showChromeVersionError();
        return;
    }

    const loggedInPromise = () => loggedCheck().then(({ isValid, iamToken }) => {
        if (!isValid) {
            return initLoginPromise().then(saveToken);
        }
        saveIamTokenData(iamToken);
        return Promise.resolve();
    });

    const initConfigurationProfile = () => getApplicationProfile().then(({ profile }) => {
        AV.internal.profile = profile;
        setPageTitle();
        return Promise.resolve();
    });

    const eulaPromise = ({ eulaVersion, userEulaVersion }) => {
        if (eulaVersion !== userEulaVersion) {
            return initEulaPromise();
        }
        return Promise.resolve();
    };
    const initSystemsStore = () => {
        return initStore().then(({ systems, serviceRoots }) => {
            AV.Systems = systems;
            AV.ServiceRoots = serviceRoots;
        });
    };

    return initConfigurationProfile()
        .then(loggedInPromise)
        .then(initSettingsPromise)
        .then(eulaPromise)
        .then(() => Promise.all([getUserConfig(), getIsAdministrator(), initSystemsStore()]))
        .then(([{ entity }, isAdmin]) => {
            return validateLocation(isAdmin).then((result) => {
                if (!result) {
                    return Promise.resolve();
                }
                AV.User = new User({
                    name: entity && entity.alias,
                    isAdministrator: isAdmin,
                    locale: AV.internal.userSettings.locale,
                });
                return import(/* webpackChunkName: "module-loader" */ '../../module-loader/main/module-loader');
            });
        })
        .catch((err) => {
            const msg = err && err.message;
            if (msg === 'accessDenied') {
                AV.LoginProvider.endSession();
                handleAccessDeniedError();
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            }
            if (msg !== 'accessDenied') {
                console.error('Layout initialization failed', err);
                handleFatalError(new Error('Module loading failed because of a backend issue.'));
                return Promise.reject(err);
            }
        });
};
/* eslint-enable consistent-return */
