/*
 * Copyright 2017, 2020 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import { getDataModel, getDataModelLink, getLocale } from './utils';
import Registry from './registry';

/**
 * Data provider class.
 * Provides base API for working with aggregated data model from CTMS Registry.
 * getAggregatedModel - returns aggregated data model.
 * getAttributes - returns attributes.
 */
class DataModel {
    /**
     * Returns aggregated model.
     * @returns {Promise} aggregated model.
     */
    getAggregatedModel() {
        return Registry.getServiceRoots().then(response => getDataModel(response));
    }

    /**
     * Returns localized list of attributes.
     * @returns {Promise} List of attributes.
     */
    getAttributes() {
        return this.getAggregatedModel()
            .then((result) => {
                const dataModelLink = getDataModelLink(result);
                if (!dataModelLink) {
                    return Promise.reject({
                        errorMessage: 'Link datamodel:aggregated-model do not exist'
                    });
                }
                const lang = getLocale();
                const queryParams = { lang };
                return get(dataModelLink, queryParams);
            });
    }
}

export default new DataModel();
