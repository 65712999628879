/*
 * Copyright 2017-2019, 2021 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import { ADMIN_CONTEXT_ID } from '../../util/config';

const AUTH_ID = 'Y29tLmF2aWQubWVkaWFjZW50cmFsY2xvdWQtYTk1YjNmOTM4NDk3NWE1MzVjZTAwNTMzMTUzMWY2ZmI6Yzg2M2' +
    'JhNmM0ZTAzZDM1NWEzZDI5NjY0MWQ2NTY2ZWYzZTU0Y2ZiZTU1MzQ5NjliNWY3MmY1YmQ5NTZhNzE5MQ==';

export default ({
    username,
    password,
    link,
    isAdminLogin
}) => {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', 'password');
    data.append('no_refresh_token', true);
    data.append('scope', 'openid');

    if (isAdminLogin) {
        data.append('context_id', ADMIN_CONTEXT_ID);
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Basic ${AUTH_ID}`);


    return fetch(link, {
        method: 'post',
        credentials: 'same-origin',
        headers,
        body: data
    });
};

export function kerberosLogin({
                    link, isAdminLogin
                }) {
    const data = new URLSearchParams();
    data.append('grant_type', 'kerberos');
    data.append('client_credentials', AUTH_ID);
    data.append('no_refresh_token', true);
    data.append('scope', 'openid');

    if (isAdminLogin) {
        data.append('context_id', ADMIN_CONTEXT_ID);
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    return fetch(link, {
        method: 'post',
        credentials: 'same-origin',
        headers,
        body: data
    });
}

export function oktaLogin({
    link, isAdminLogin
}) {
    const data = new URLSearchParams();
    data.append('disable_redirect', 'true');
    data.append('scope', 'openid'); // for multisite

    if (isAdminLogin) {
        data.append('context_id', ADMIN_CONTEXT_ID);
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Basic ${AUTH_ID}`);

    return fetch(link, {
        method: 'post',
        credentials: 'same-origin',
        headers,
        body: data
    });
}
