/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import { checkLanguageSupport, checkDateStyleSupport } from '../util/settingsValidation';
import { ADMIN_LOCALE } from '../../configs/admin-locale';

export default (userSettings, isAdmin) => {
    const generalSettings = Object.assign({}, AV.internal.generalSystemSettings);
    AV.internal.userSettings = userSettings;
    AV.internal.userSettings.dateStyle = checkDateStyleSupport(userSettings.dateStyle)
        ? userSettings.dateStyle
        : generalSettings.dateFormat;
    if (isAdmin) {
        AV.internal.userSettings.locale = ADMIN_LOCALE;
    } else {
        AV.internal.userSettings.locale = checkLanguageSupport(userSettings.locale)
            ? userSettings.locale
            : generalSettings.language;
    }
    // Used for Ext localization
    window.userLocale = AV.internal.userSettings.locale;
    AV.internal.userSettings.timeZone = userSettings.timeZone || generalSettings.timeZone;
};
