/*
 * Copyright 2019-2021 by Avid Technology, Inc.
 */

import styles from '../../../plugins/com.avid.central.UICore/main/js/layout/root.scss';
import localizationStorage from '../../login/localization/storage';

export const MINIMAL_CHROME_VERSION = 61;

export const showChromeVersionError = () => {
    const blockMessage = document.createElement('div');
    const header = document.createElement('h1');
    const message = document.createElement('h3');

    blockMessage.classList.add(styles['incorrect-chrome-version']);
    header.innerText = localizationStorage.getLocalization('unsupported-browser-error-header');
    message.innerText = localizationStorage.getLocalization('unsupported-browser-error-message', MINIMAL_CHROME_VERSION);

    blockMessage.appendChild(header);
    blockMessage.appendChild(message);
    document.getElementById('avid-nux-page-load-indicator').remove();
    document.body.appendChild(blockMessage);
};
