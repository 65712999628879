/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import hal from 'halfred';
import { identityProviderConfig } from './config';

const getEmbedded = (item, embedName) => {
    const resource = hal.parse(item);
    return resource.embeddedArray(embedName);
};

function getIdentityProviderLink(data, providerConfig) {
    const halProviders = getEmbedded(data, identityProviderConfig.PROVIDERS_KEY);
    const provider = halProviders.find((it) => {
        return it.kind === providerConfig.kind && it.link(providerConfig.key);
    });
    return provider ? provider.link(providerConfig.key).href : null;
}

export function getProviderLinks(data) {
    const defaultProviderLink = getIdentityProviderLink(data, identityProviderConfig.DEFAULT);
    const kerberosProviderLink = getIdentityProviderLink(data, identityProviderConfig.KERBEROS);
    const oktaProviderLink = getIdentityProviderLink(data, identityProviderConfig.OKTA);

    return {
        providers: {
            default: defaultProviderLink,
            kerberos: kerberosProviderLink,
            okta: oktaProviderLink,
        }
    };
}
