/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { get, put } from './http-requests';
import config from './config';

export default {

    /**
     * Returns value by attribute name.
     * @param {String} name Attribute name.
     * @return {Promise} Attribute value.
     */
    fetch: (name) => {
        if (!name) {
            return Promise.reject({ errorMessage: 'Attribute name was not specified' });
        }
        const url = `${config.attributesService}attributes`;
        return get(url, { name })
            .catch((err) => {
                if (err.status === 404) {
                    console.warn('Attribute not found');
                } else {
                    console.warn('Failed to fetch attribute');
                }
            });
    },

    /**
     * Stores attribute by name and value.
     * @param {String} name Attribute name.
     * @param {String|Array|Object} value Attribute value.
     * @return {Promise} Service response.
     */
    store: (name, value) => {
        if (!name) {
            return Promise.reject({ errorMessage: 'Attribute name was not specified' });
        }
        if (typeof value === 'undefined') {
            return Promise.reject({ errorMessage: 'Attribute value was not specified' });
        }
        const url = `${config.attributesService}attributes`;
        return put(url, { name, value });
    }
};
