/*
 * Copyright 2021 by Avid Technology, Inc.
 */

const DEFAULT_PROFILE = { profile: 'default' };

export default () => {
    return fetch('/configuration/profile.json', {
        headers: {
            Accept: 'application/json'
        },
        credentials: 'same-origin'
    }).then((response) => {
        if (!response.ok) {
            return DEFAULT_PROFILE;
        }
        return response.json();
    }).catch((err) => {
        console.warn('[Profile configuration] Retrieving error', err);
        console.warn('[Profile configuration] CloudUX proceeds with default profile');
        return DEFAULT_PROFILE;
    });
};

