/*
 * Copyright 2018, 2020-2021 by Avid Technology, Inc.
 */

import hasEmbeddedEntitlementError from './hasEmbeddedEntitlementError';
import localizationStorage from '../login/localization/storage';

const ENTITLEMENT_CHECK_ERROR = localizationStorage.getLocalization('entitlement-check-error');
const USER_LICENSE_SERVICE = '/apis/avid.user.license;version=0;realm=global/entitlements';

async function get() {
    try {
        return await fetch(USER_LICENSE_SERVICE, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        });
    } catch (err) {
        throw new Error(`[Entitlements] Entitlements list request was failed: ${err}`);
    }
}

async function validateEntitlements(entitlements, embeddedInMode) {
    const result = {
        isValid: false,
        message: ''
    };
    try {
        const response = await get();
        const body = await response.json();
        if (response.status === 401 || !response.ok) {
            result.message = body.message;
            return result;
        }
        if (hasEmbeddedEntitlementError(body.entitlements, entitlements, embeddedInMode)) {
            result.message = ENTITLEMENT_CHECK_ERROR;
            return result;
        }
        result.isValid = true;
        return result;
    } catch (err) {
        console.log('[validateEntitlements]', err);
        return result;
    }
}

export { validateEntitlements };
